import { skills } from "../../static/constants";
import { Container, Skill,Wrapper,Title, Desc, SkillsContainer, SkillItem, SkillTitle, SkillList, SkillImage } from "./SkillsStyles";


const Skills = ()=> {
    skills.forEach((skill)=> console.log(skill));
    return(
        <Container id="skills">
            <Wrapper>
                <Title>Skills</Title>
                <Desc>Here are some of my skills on which I have been working</Desc>
                <SkillsContainer>
                    {skills.map((skill) => (
                        <Skill>
                            <SkillTitle>{skill.title}</SkillTitle>
                            <SkillList>
                                {skill.skills.map((item) => (
                                    <SkillItem>
                                        <SkillImage src={item.image}/>
                                            {item.name}
                                        
                                    </SkillItem>

                                ))}
                            </SkillList>
                        </Skill>
                    ))}
                </SkillsContainer>
            </Wrapper>
        </Container>
    );
}

export default Skills;