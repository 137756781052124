import React from 'react'
import { MainContainer,MainBg, MainInnerContainer, MainLeftContainer, Title, TextLoop, SubTitle, ResumeButton, MainRightContainer, Img , Span,BadgeImage,BadgeLink} from './MainStyle'
import BgAnimation from '../BackgroundAnimation';
import { Bio } from '../../static/constants';

import Typewriter from 'typewriter-effect';
import MainImg from '../../images/DP.png';
import LeetcodeBage from '../../images/2023-50.gif';
const MainSection = () => {
    return (
        <div id="about">
            <MainContainer>
                <MainBg>
                    <BgAnimation/>
                </MainBg>
                <MainInnerContainer>
                    <MainLeftContainer id="left">
                        <Title>Hi, I am <br /> {Bio.name}<BadgeLink href={Bio.leetcode} target="_blank"><BadgeImage src={LeetcodeBage}/></BadgeLink></Title>
                        <TextLoop>
                            I am a
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop:true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{Bio.description}</SubTitle>
                        <ResumeButton href={Bio.resume} target='display'>Check Resume</ResumeButton>
                    </MainLeftContainer>
                    <MainRightContainer id="Right">
                            <Img src={MainImg} alt="main-image"></Img>
                    </MainRightContainer>
                </MainInnerContainer>
            </MainContainer>
        </div>
    )
}

export default MainSection